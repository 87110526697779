import React from "react";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { gsap } from "gsap";
import Bio from "../components/bio";
import Layout from "../components/layout";
import Share from "../components/Share";
import SEO from "../components/seo";
import Newsletter from "../components/newsletter.js";
import CTA from "../components/cta.js";

class UePostTemplate extends React.Component {
  componentDidMount() {
    gsap.to("#a1", { x: 500, duration: 0 });
    gsap.to("#a1", { x: 0, duration: 4 });
    gsap.to("#a2", { x: -500, duration: 0 });
    gsap.to("#a2", { x: 50, duration: 4, delay: 1 });
  }

  render() {
    const post = this.props.data.mdx;
    const siteTitle = this.props.data.site.siteMetadata.title;
    //console.log(post)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <div className="x-wrapper">
          <div
            className="columns"
            style={{
              minHeight: "500px",
              padding: "0px",
              margin: "0px",
              alignItems: "center",
              backgroundColor: "white"
            }}
          >
            <div
              id="lcol"
              className="column nopad"
              style={{
                justifyContent: "center",
                padding: "2% 0%",
                maxWidth: "25%"
              }}
            >
              <div className="nopadleft" style={{ paddingLeft: "3rem" }}>
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2em",
                    marginTop: "30px"
                  }}
                  className="title"
                >
                  <strong style={{ color: "#00ff00" }}>
                    {post.frontmatter.title}{" "}
                  </strong>
                  <br />
                  <span style={{ fontSize: ".5em" }}>
                    {" "}
                    REALIZUJEMY PROJEKTY DOFINANSOWANE
                    <br />Z FUNDUSZY EUROPEJSKICH
                  </span>
                </h1>
                <hr
                  style={{
                    maxWidth: "150px",
                    backgroundColor: "#333333",
                    height: "3px"
                  }}
                />
                <div
                  className="show-mob"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <img
                    id="mobile-x"
                    src="/img/x.svg"
                    style={{
                      marginBottom: "20px",
                      maxWidth: "500px",
                      marginTop: "-75px"
                    }}
                    width="auto"
                    height="auto"
                    alt="Rexer engineering"
                  />
                </div>
                <p>
                  Jesteśmy uczestnikami unijnych programów dla{" "}
                  <strong>innowacyjnych przedsiębiorstw</strong>. Dbamy o
                  ekologię, przechodzimy cyfryzację - dla dobra przyszłości
                  naszej planety.
                </p>
                <a href="#main" className="button rexer-button">
                  {" "}
                  Dowiedz się więcej
                </a>
              </div>
              <br />
              <div
                className="column mob-hide is-two-thirds"
                style={{ position: "relative" }}
              >
                <img
                  id="a2"
                  style={{
                    marginTop: "-50px",
                    marginBottom: "-30%",
                    marginLeft: "-20px",
                    maxWidth: "450px",
                    opacity: "1"
                  }}
                  src="/img/eco-bulb.png"
                  width="auto"
                  alt="animacja-kula"
                />
              </div>
            </div>

            <div
              id="midcol"
              className="column"
              style={{ width: "50%", marginTop: "-100px", textAlign: "center" }}
            >
              <img
                src="/img/x.svg"
                style={{ marginBottom: "0" }}
                width="auto"
                height="auto"
                alt="Rexer engineering"
              />
              <p
                id="paddmob"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <a
                  href="#main"
                  style={{ border: "0px solid white", marginTop: "0px" }}
                  className="button standard-button"
                >
                  ↓
                </a>
              </p>
            </div>

            <div
              id="rcol"
              className="column"
              style={{
                maxWidth: "25%",
                margin: "0px",
                marginTop: "-175px",
                padding: "0px",
                alignItems: "end",
                display: "block",
                justifyContent: "center",
                marginRight: "3em"
              }}
            >
              <div style={{ textAlign: "right", width: "100%" }}>
                <img
                  id="a1"
                  src="/img/eu1.png"
                  width="100%"
                  height="auto"
                  alt="Rexer engineering"
                  style={{
                    minWidth: "300px",
                    maxWidth: "400px",
                    opacity: "1",
                    marginBottom: "0px",
                    borderBottom: "3px solid #f6f6f6",
                    backgroundImage: "url(/img/hero-bg-home.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                  }}
                />
              </div>
              <div
                className="deco-w"
                style={{
                  border: "5px solid #333333",
                  padding: "20px",
                  marginTop: "3em",
                  marginLeft: "1rem"
                }}
              >
                <p
                  className="deco"
                  style={{ textAlign: "right", backgroundColor: "white" }}
                >
                  Dowiedz się więcej o naszych aktualnych projektach i
                  postepowaniach.
                </p>
              </div>
              <p
                id="marginmob"
                style={{ textAlign: "right", marginTop: "3em" }}
              >
                <a href="#render" className="button rexer-button">
                  Realizowane projekty
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="is-bold"
          style={{
            textAlign: "left",
            backgroundColor: "#f6f6f6",
            padding: "5%"
          }}
        >
          <h2
            className="subtitle is-size-5"
            style={{
              borderLeft: "5px solid #00ff00",
              marginTop: "50px",
              paddingLeft: "10px",
              marginLeft: "-10px"
            }}
          >
            <strong style={{ textTransform: "uppercase" }}>
              AKTUALNE OGŁOSZENIA
            </strong>
          </h2>
          <br />
          <p className="is-size-5" style={{ fontWeight: "400" }}>
            <strong>REXER</strong> cały czas poszukuje najnowocześniejszych
            rozwiązań technologicznych, aby zapewnić najwyższą jakość oraz
            wydajność oferowanych przez nas usług. Wszelkie informacje na temat
            szczegółów prowadzonych projektów, postawionych sobie celów i
            aktualnych postępowań dostępne są dla Państwa poniżej.
          </p>
        </div>

        <div id="render" style={{ padding: "2% 5%" }}>
          <MDXRenderer>{post.body}</MDXRenderer>
          <hr />
          {/*<span style={{fontSize:'11px'}}>Ostatnia aktualizacja: <strong>{post.frontmatter.date}</strong></span>
        <br /><br />
        <div style={{padding:'2% 0%'}}>
        <Bio />
        <br /><br />
        <Share
          title={post.frontmatter.title}
          slug={post.slug}
          excerpt={post.frontmatter.description}
          siteUrl="https://rexer.pl"
          pathPrefix="/kariera/"
        />
        </div>
        <br /><br />
        <div style={{textAlign:'center'}}>
        <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/">Home</Link>
        <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/o-nas/">O nas</Link>
        <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/oferta/">Oferta</Link>
        <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/realizacje/">Realizacje</Link>
        <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/aktualnosci/">Aktualności</Link>
        <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/sport/">Sport</Link>
        <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/kariera/">Kariera</Link>
        </div>*/}
        </div>
        <div></div>
        <Newsletter />
        <CTA />
      </Layout>
    );
  }
}

export default UePostTemplate;

export const mpageQuery = graphql`
  query UePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 158)
      body
      frontmatter {
        title
        templateKey
        date(formatString: "DD. MM. YYYY")
        language
        description
      }
    }
  }
`;
